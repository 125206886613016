@import "../../../../global.scss";

.sbdc-timeline{
    padding: 12% 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 50%;
        left: 0;
        top: 0;
        background-color: #E1FCFF;
        z-index: 1;
    }
    .sbdc-timeline-header{
        position: absolute;
        top: 7%;
        h2{
            font-size: 5vmin;
            color: $primaryColor;
            text-transform: capitalize;
            font-weight: 900;
            position: relative;
            padding-bottom: 8%;
            &::after{
                content: "";
                position: absolute;
                height: 5px;
                width: 40%;
                background-color: $secondaryColor;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    .sbdc-timeline-logo{
        z-index: 2;
        margin-top: -3.5%;
        width: 100%;
    }
    .sbdc-timeline-logo-mobile{
        z-index: 2;
        margin-top: -2%;
        width: 100%;
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .sbdc-timeline{
        .sbdc-timeline-header{
            h2{
                padding-bottom: 5%;
                font-size: 4vmin;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .sbdc-timeline{
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 46%;
            left: 0;
            bottom: 0;
            background-color: #E1FCFF;
            z-index: 1;
        }
        .sbdc-timeline-header{
            h2{
                padding-bottom: 8%;
                font-size: 5vmin;
            }
        }
        .sbdc-timeline-logo{
            display: none;
        }
        .sbdc-timeline-logo-mobile{
            z-index: 2;
            width: 85%;
            margin-left: 10%;
            display: block;
            margin-top: 20%;

        }
    }
}