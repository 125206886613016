@import "../../../../global.scss";

.draft-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.404);

  display: flex;
  align-items: center;
  justify-content: center;
  .draft-popup {
    height: 400px;
    width: 600px;
    padding: 50px 30px;
    background-color: white;
    color: black;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    h1 {
      margin-bottom: 50px;
    }
    a {
      margin-top: auto;
      text-decoration: none;
      color: white;
      background-color: #2ad352;
      padding: 10px 40px;
      border-radius: 5px;
    }
    p {
      font-size: 16px;
      margin-top: 20px;
    }
  }
}
