@import "../../../../global.scss";
.sbdc-form{
    padding: 10%;
    background-color: $lightBlue;
    .sbdc-form-header{
        h2{
            color: $primaryColor;
            font-weight: 900;
            text-transform: uppercase;
            font-size: 2.4rem;
        }
        p{
            color: $primaryColor;
            font-weight: 500;
        }
    }
    .sbdc-form-body{
        input, textarea{
            padding: 10px 16px;
            outline: none;
            border: none;
            width: 100%;
            background-color: inherit;
            color: $primaryColor;
            border-bottom: 1px solid $form;
            margin-top: 2rem;
            &::placeholder{
                color: $form;
                opacity: 1;
                font-weight: 500;
            }
        }
        button{
            outline: 0;
            border: 2px solid $primaryColor;
            background-color: $white;
            color: $primaryColor;
            border-radius: 5px;
            padding: 8px 32px;
            text-transform: capitalize;
            font-weight: 700;
        }
    }
}