@import "../../global.scss";

.sfdc-registration {
  .sfdc-registration-header {
    background: #fff5e4;
    .sfdc-registration-header-left {
      position: relative;
      padding: 5% 8%;
      @media screen and (max-width: 990px) {
        padding-bottom: 5%;
      }
      p {
        color: $text;
        .deepblue {
          color: $darkblue;
          font-weight: bold;
        }
      }
      ol {
        padding-left: 3%;
        li {
          margin-top: 1%;
        }
        .highlight-left {
          font-weight: 700;
          font-style: italic;
        }
      }
      .login-btn {
        a {
          padding: 10px 16px;
          border-radius: 5px;
          background-color: $primaryColor;
          color: $white;
          text-decoration: none;
          cursor: pointer;
          font-weight: 500;
          font-size: 18px;
        }
        button {
          outline: 0;
          border: 0;
          padding: 10px 16px;
          border-radius: 5px;
          background-color: $primaryColor;
          color: $white;
          text-decoration: none;
          cursor: pointer;
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
    .sfdc-registration-header-right {
      padding: 4% 7.5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 990px) {
        padding-top: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .image-container1 {
        height: 350px;
        aspect-ratio: 1;
      }
      .image-container2 {
        height: 130px;
      }
      .image-container3 {
        height: 110px;
      }
    }
    .sfdc-registration-header-headline {
      position: absolute;
      bottom: -33.5px;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (max-width: 601px) {
        margin-top: 1.5rem;
      }
      p {
        color: $white;
        background: $primaryColor;
        padding: 20px 60px;
        border-radius: 10px;
        text-align: center;
        font-weight: 900;
        text-transform: capitalize;
        font-size: 24px;
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .sfdc-registration {
    .sfdc-registration-header {
      .sfdc-registration-header-headline {
        bottom: -74px;
        p {
          font-size: 18px;
          padding: 10px 60px;

          @media screen and (max-width: 600px) {
            padding: 20px;
          }
        }
      }
    }
  }
}
