@import "../../../../global";

.sbdc-registration {
    .sbdc-registration-header {
        background: #ebfdff;
        .sbdc-registration-header-left {
            padding: 10% 8% 10% 15%;
            p {
                color: $text;
            }
            ol{
                padding-left: 3%;
                li{
                    margin-top: 1%;
                }
                .highlight-left{
                    font-weight: 700;
                    font-style: italic;
                }
            }
        }
        .sbdc-registration-header-right {
            padding: 10% 15% 10% 10%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 600px) {
                flex-direction: column;
            }
            img {
                width: 100%;
                object-fit: cover;
            }
            .sbdc-registration-footer{
                position: absolute;
                bottom: 0;
                right: 0;
                height: 100px;
                width: 400px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media screen and (max-width: 600px) {
                    position: relative;
                }
                img{
                    width: 51px;
                    height: 50px;
                }
                .registration-footer-content{
                    padding: 2.5% 5%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #48c9d8;
                    height: 100%;
                    border-top-left-radius: 30px;
                    @media screen and (max-width: 600px) {
                        margin-top: 10%;
                    }
                    .marked{
                        color: #fff;
                    }
                    h2{
                        color: #1C4065;
                        font-weight: 900;
                        font-size: 36px;
                        text-transform: uppercase;
                        margin-bottom: 0;
                    }
                }
            }
        }
        .sbdc-registration-header-headline {
            position: absolute;
            bottom: -33.5px;
            left: 50%;
            transform: translateX(-50%);
            @media screen and (max-width: 601px) {
                margin-top: 1.5rem;
            }
            p {
                color: $white;
                background: $primaryColor;
                padding: 20px 60px;
                border-radius: 10px;
                text-align: center;
                font-weight: 900;
                text-transform: capitalize;
                font-size: 24px;
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .sbdc-registration {
        .sbdc-registration-header {
            .sbdc-registration-header-headline {
                bottom: -74px;
                p {
                    font-size: 18px;
                    padding: 10px 60px;
                }
            }
        }
    }
}
