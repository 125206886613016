@import "../../../global.scss";
.main-footer {
  display: flex;
  &__container {
    padding: 50px 100px;
    border-radius: 0 188px 0 0;
    width: max-content;
    height: 100%;
    background-color: #223c4d;

    display: flex;
    justify-content: space-between;
    gap: 5rem;

    @media screen and (max-width: 1024px) {
      padding: 50px;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      width: 100%;
      padding: 30px 40px;
      border-radius: 0;
      gap: 0;
    }
    @media screen and (min-width: 1921px) {
      width: 70%;
    }
    h3,
    .logo-container {
      margin-bottom: 2rem;
    }
    h3 {
      font-size: 24px;
      font-weight: 700;
      color: #ff9a4e;

      @media screen and (max-width: 1220px) {
        font-size: 20px;
      }
      @media screen and (max-width: 600px) {
        margin-bottom: 1rem;
        margin-top: 1rem;
      }
    }
    span {
      font-size: 11px;
      color: white;
    }
    p {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 0;
      color: white;

      @media screen and (max-width: 1220px) {
        font-size: 14px;
      }
    }
    .brought-by {
      display: flex;
      flex-direction: column;
      .logo-container {
        margin-bottom: 2rem;
        display: flex;
        gap: 1rem;

        @media screen and (max-width: 1024px) {
          flex-direction: column;
        }

        @media screen and (max-width: 600px) {
          flex-direction: column;
          margin-bottom: 0;
        }

        img {
          height: 45px;
          width: 180px;
        }
      }
    }

    .reserved-1 {
      margin-top: auto;
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
    .reserved-2 {
      display: none;
      @media screen and (max-width: 600px) {
        display: inline;
        margin-top: 20px;
        // margin-top: 30px;
      }
    }
  }
  &__arrow-container {
    align-self: center;
    margin-inline: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 600px) {
      display: none;
    }

    img {
      height: 50px;
      width: 50px;
    }
  }
}
