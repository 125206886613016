@import "../../../../../global.scss";

.media-container {
  display: flex;
  align-items: center;
  gap: 1rem;

  img {
    @media screen and (max-width: 1220px) {
      height: 30px;
    }
  }
  .whatsapp-icon {
    height: 65px;
    @media screen and (max-width: 1220px) {
      height: 45px !important;
    }
  }
}
