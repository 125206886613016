@import "../../../global";

.navigation-move-above {
  transform: translateY(-100%);
}
.navigation {
  padding: 1em 0;
  background-image: linear-gradient(to right, $primaryColor, #356aa0);
  border-bottom: 8px solid $form;
  position: sticky;
  top: 0;
  z-index: 1000000;
  transition: transform 400ms cubic-bezier(0.47, 0, 0.745, 0.715) 100ms;
  .navigation-container {
    max-width: 85%;
    margin: 0 auto;
    .navigation-hamburger-menu {
      display: none;
      z-index: 100;
      @media screen and (max-width: 601px) {
        display: block;
      }
    }
    .navigation-logo {
      z-index: 100;
      @media screen and (max-width: 900px) {
        img {
          width: 200px;
        }
      }
    }
    .navigation-menu {
      display: inline-flex;
      gap: 4em;
      justify-content: space-between;
      @media screen and (max-width: 601px) {
        display: none;
      }
      margin-left: 0.5em;
      a {
        text-decoration: none;
        color: $white;
        flex-shrink: 1;
        font-weight: 900;
        text-transform: uppercase;
        padding: 10px 20px;
        margin: 0 0;
        border-radius: 3px;
        transition: all 0.2s;

        &:hover {
          background-color: #64a0dc;
        }
      }
      .sbdc-dropdown {
        position: relative;
        display: inline-block;
        i {
          font-size: 0.8rem;
          margin-left: 0.5rem;
        }
        &:hover {
          .sbdc-dropdown-content {
            display: block;
          }
        }
        .sbdc-dropdown-content {
          background-color: khaki;
          display: none;
          position: absolute;
          background-color: #ffffff;
          min-width: 160px;
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
          z-index: 1;
          border-radius: 5px;
          margin-top: 0.5rem;
          a {
            color: #64a0dc;
            padding: 16px 16px;
            font-size: 15px;
            font-weight: 700;
            text-decoration: none;
            display: block;
            transition: 0.3s;
            &:hover {
              color: $white;
              background-color: #64a0dc;
            }
          }
        }
      }
    }
    .mobile-navigation-menu {
      position: fixed;
      top: 0;
      right: 0;
      display: block;
      height: 100vh;
      width: 100vw;
      z-index: 99;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: linear-gradient(to right, #224b75, #356aa0);
      transition: transform 0.5s ease-in-out;
      transform: translateX(-100%);
      @media screen and (min-width: 601px) {
        display: none;
      }

      &.appear {
        transform: translateX(0);
      }
      a {
        text-decoration: none;
        color: $white;
        flex-shrink: 1;
        font-weight: 900;
        text-transform: uppercase;
        // background-color: $form;
        padding: 10px 0;
        margin: 0 0;
        border-radius: 3px;
      }
    }
  }
}
