@import "../../global.scss";

.infobar {
    position: relative;
    overflow: hidden;
    height: 70vh;
    font-family: $font-collection;
    background-color: $darkblue;
    padding: 1em;
    .flex-container {
        width: 100%;
        height: 100%;
        z-index: 4;
        padding-top: 2em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 4;
    }
    #infobar-skyblue-circle {
        position: absolute;
        width: 400px;
        height: 400px;
        border-radius: 50%;
        background-color: $form;
        bottom: 0;
        right: 0;
        top: unset;
        left: unset;
        transform: translate(50%, 50%);
        z-index: 0;
        @media screen and (min-width: 480px) {
            left: 0;
            top: 0;
            bottom: unset;
            right: unset;
            transform: translate(-50%, -50%);
        }
    }
    h4 {
        font-size: 1.4rem;
        color: $white;
        z-index: 4;
        font-weight: bold;
        text-align: center;
        span#infobar-blue-text {
            color: $form;
        }
        @media screen and (min-width: 1200px) {
            font-size: 2em;
        }
    }
    .button-container {
        margin-top: 3rem;
    }
}
