@import "../../../../../global.scss";

.hero-btn {
  font-size: 30px;
  font-weight: 700;
  padding: 20px 50px;
  color: white;
  background-color: var(--sfdc-secondary-color);
  text-decoration: none;
  width: max-content;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  border: none;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;

  @media screen and (max-width: 1600px) {
    font-size: 25px;
    padding: 15px 40px;
  }
  @media screen and (max-width: 1440px) {
    font-size: 20px;
    padding: 10px 30px;
  }
  @media screen and (max-width: 600px) {
    font-size: 16px;
    padding: 8px 20px;
  }
  &:hover {
    color: white;
  }

  &:hover .arrow-icon {
    margin-left: 10px;
  }

  .arrow-icon {
    transition: all 0.2s;
    @media screen and (max-width: 1440px) {
      width: 25px;
    }
    @media screen and (max-width: 600px) {
      width: 20px;
    }
  }
}
