@import "../../../../global.scss";
.submit-idea{
    padding: 10%;
    img{
        position: absolute;
        left: 0;
        bottom: 0;
    }
    h3{
        text-transform: capitalize;
        color: $deepSkyBlue;
        font-weight: 600;
        font-size: 2.2rem;
    }
    h2{
        color: $secondaryColor;
        font-weight: 900;
        font-size: 2.5rem;
    }
    button{
        outline: none;
        border: 3px solid $primaryColor;
        padding: 13px 35px;
        font-size: 1.1rem;
        background-color: $primaryColor;
        color: $white;
        font-weight: 700;
        text-transform: capitalize;
        border-radius: 10px;
        margin-top: 2rem;  
        transition: 0.3s; 
        &:hover{
            background-color: $white;
            color: $primaryColor;
        } 
    }
}

@media screen and (max-width: 900px){
    .submit-idea{
        h3{
            font-size: 1.8rem;
        }
        h2{
            font-size: 2.1rem;
        }
        button{
            padding: 11px 32px;
            font-size: 1rem;
        }
        img{
            height: 50%;
        }
    }
}

@media screen and (max-width: 500px){
    .submit-idea{
        img{
            display: none;
        }
    }
}