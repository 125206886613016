@import "../../../../global.scss";
.sbdc-reward{
    .sbdc-reward-header{
        height: 500px;
        padding: 10%;
        background-color: $primaryColor;
        position: relative;
        .winning-head{
            font-size: 7vmin;
            color: $deepSkyBlue;
            font-weight: 900;
            text-transform: capitalize;
        }
        .reward-head{
            font-size: 7vmin;
            color: $white;
            font-weight: 900;
            text-transform: capitalize;
        }
        hr{
            opacity: 1;
            height: 5px;
            width: 50%;
            color: $deepSkyBlue;
        }
        .reward-coin{
            position: absolute;
            right: 0;
            bottom: 0;
            img{
                height: 25vmin;
            }
        }
    }
    .sbdc-reward-body{
        height: 500px;
        padding: 7.5% 10%;
        background-color: $lightBlue;
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: relative;
        @media screen and (max-width: 600px) {
            height: auto;
            grid-template-columns: 1fr;
        }
        .reward-body-top{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media screen and (max-width:600px){
                height: 300px;
            }
            h3{
                color: #1C4065;
                font-size: 24px;
            }
            .marked{
                color: #1C4065;
                font-weight: 900;
            }
            .reward-prize{
                padding: 2% 4%;
                background-color: #1C4065;
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 300px;
                border-radius: 10px;
                margin-top: 2.5%;
                box-shadow: 10px 10px 0 #48c9d8;
                h2{
                    font-weight: 900;
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 42px;
                    margin-bottom: 0;
                }
            }
            p{
                color: #1C4065;
                font-size: 18px;
            }
            h2{
                color: #1C4065;
                font-weight: 900;
                font-size: 36px;
            }
        }
        .reward-body-bottom{
            @media screen and (max-width: 600px) {
                height: 400px;
            }
            .reward-cup{
                height: 400px;
                position: absolute;
                width: 50%;
                top: 0;
                right: 0;
                @media screen and (max-width: 600px) {
                    position: relative;
                }
                img{
                    position: absolute;
                    height: 300px;
                    bottom: 0;
                    left: 25%;
                    @media screen and (max-width: 600px) {
                        height: 350px;
                        left: 0;
                    }
                    @media screen and (min-width: 1441px) {
                        height: 350px;
                        left: 50%;
                    }
                }
            }
            .reward-date{
                height: 100px;
                padding: 3% 4%;
                background-color: #ACE08A;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: 0;
                right: 0;
                border-top-left-radius: 50px;
                h2{
                    color: #1C4065;
                    font-size: 42px;
                    font-weight: 900;
                    margin-bottom: 0;
                }
            }
        }
    }
}

// @media screen and (min-width: 1441px){
//     .sbdc-reward{
//         .sbdc-reward-header{
//             min-height: 600px;
//             .winning-head{
//                 font-size: 6vmin;
//             }
//             .reward-head{
//                 font-size: 6vmin;
//             }
//             .reward-coin{
//                 img{
//                     height: 30vmin;
//                 }
//             }
//         }
//         .sbdc-reward-body{
//             min-height: 600px;
//             padding: 10%;
//             background-color: $lightBlue;
//             .reward-list{
//                 ul{
//                     width: 100%;
//                     li {
//                         padding: 0rem 0 1rem 3em;
//                         font-size: 3vmin;
//                         background: url("../../../../assets/list-arrow.svg")
//                             no-repeat 7px 0px transparent;
//                         @media screen and (max-width: 700px) {
//                             background: url("../../../../assets/list-arrow.svg")
//                                 no-repeat 2px 2px transparent;
//                         }
//                         list-style-type: none;
//                         margin-top: 1rem;
//                     }
//                 }
//             }
//         }
//     }
// }

@media screen and (max-width: 900px) {
    .sbdc-reward{
        .sbdc-reward-header{
            .winning-head{
                font-size: 3rem;
            }
            .reward-head{
                font-size: 3rem;
            }
            .reward-coin{
                img{
                    height: 25vmin;
                }
            }
        }
        .sbdc-reward-body{
            .reward-list{
                ul{
                    li{
                        font-size: 2vmin;
                    }
                }
            }
            .reward-art{
                display: none;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .sbdc-reward{
        .sbdc-reward-header{
            .reward-coin{
                img{
                    height: 50vmin;
                }
            }
        }
        .sbdc-reward-body{
            .reward-list{
                ul{
                    li{
                        font-size: 1.2rem;
                        padding-left: 5rem;
                    }
                }
            }
        }
    }
}