@import "../../global.scss";

.home-main-section {
  display: grid;
  grid-template-columns: 1fr;
  font-family: $font-collection;
  background: $bg-light;
  @media screen and (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-template-areas: "a b" "c d" "e f";
  }
  section {
    min-height: 70vh;
    // max-height: 650px;
    text-align: center;
    &:last-child {
      @media screen and (min-width: 480px) {
        grid-column: span 2;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .home-header {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    @media screen and (max-width: 1150px) {
      padding: 1em;
    }
    margin-inline: auto;
    .home-header-text {
      margin-block: 3em;
      margin-bottom: 1em;
      width: 100%;

      h2 {
        font-size: 1.2rem;
        font-weight: bold;
        color: $darkblue;
        text-transform: uppercase;
        z-index: 15;
        @media screen and (min-width: 480px) {
          font-size: 3vw;
        }
        @media screen and (min-width: 601px) {
          font-size: 3vw;
        }
        @media screen and (min-width: 801px) {
          font-size: 3vw;
          margin-bottom: -0.3em;
        }
      }
      h1 {
        font-size: 2rem;
        font-weight: 900;
        color: $blue;
        text-transform: uppercase;
        position: relative;
        display: inline-block;
        max-width: 100%;
        // max-width: 40ch;
        white-space: nowrap;
        @media screen and (min-width: 480px) {
          font-size: 5vw;
        }
        @media screen and (min-width: 601px) {
          font-size: 5vw;
        }
        @media screen and (min-width: 801px) {
          font-size: 5vw;
          margin: 0 auto;
        }
      }
      span {
        position: relative;
        z-index: 0;
        display: inline;
        &::after {
          content: url("../../assets/blue-leaf.svg");
          position: absolute;
          display: inline;
          top: 0;
          left: 50%;
          transform: scale(0.5) translate(-100%, -100%);
          // width: 10px;
          z-index: 4;
          @media screen and (min-width: 1139px) {
            transform: scale(1) translate(-50%, -60%);
          }
          @media screen and (max-width: 760px) {
            transform: scale(0.4) translate(-125%, -135%);
          }
          @media screen and (max-width: 350px) {
            display: none;
          }
        }
      }
    }
    p.home-header-description {
      font-size: 0.8rem;
      color: $text;
      @media screen and (min-width: 480px) {
        font-size: 0.8rem;
      }
      @media screen and (min-width: 601px) {
        font-size: 0.8rem;
      }
      @media screen and (min-width: 1101px) {
        font-size: 1rem;
        max-width: 60ch;
      }
      @media screen and (min-width: 1401px) {
        font-size: 1.2rem;
        max-width: 60ch;
      }
      a {
        color: $form;
        text-decoration: none;
      }
      & ~ p {
        margin-top: 2em;
        @media screen and (max-width: 900px) {
          margin-top: 1em;
        }
      }
    }
    // home apply button
    .apply-btn-container {
      padding: 2.5% 0;
      a {
        text-decoration: none;
        color: $primaryColor;
        padding: 1% 2.5%;
        border-radius: 5px;
        border: 2px solid $primaryColor;
        display: block;
        width: fit-content;
        font-size: calc(10px + 1vmin);
        transition: 0.3s;
        &:last-child {
          margin-top: 2.5%;
        }
        &:hover {
          background-color: $primaryColor;
          color: $bg-light;
        }
      }
    }
  }
  .home-main-image {
    img[alt="header-image"] {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .sfdc-home-image {
    position: relative;
    background-color: #fff7ec;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    @media screen and (min-width: 480px) {
      grid-area: d;
    }

    #blue-triangle-deco {
      position: absolute;
      border: 40px solid transparent;
      border-left: 40px solid $darkblue;
      top: 50%;
      left: 0;

      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    img[alt="sfdc-logo"] {
      max-width: 60%;
      max-height: 60%;
      object-fit: contain;

      @media screen and (min-width: 1200px) {
        max-width: 45%;
        max-height: 45%;
      }
    }
    #light-deco-top-left {
      position: absolute;
      top: 0;
      left: 0;
      width: 30%;
      height: max-content;
      object-fit: contain;
    }
    #light-deco-bottom-right {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 30%;
      height: max-content;
      object-fit: contain;
    }
  }
  .sfdc-home-section {
    background-color: $darkblue;
    position: relative;
    text-align: left;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    @media screen and (max-width: 780px) {
      padding-inline: 1em;
    }
    @media screen and (max-width: 350px) {
      padding-inline: 1.5em;
    }
    .flex-container {
      width: fit-content;
      height: 70%;
      z-index: 4;
      padding-top: 2em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
    }
    #sfdc-blue-circle-deco {
      position: absolute;
      width: 400px;
      height: 400px;
      border-radius: 50%;
      background-color: $blue;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
      z-index: 1;
    }
    h4.sfdc-home-description {
      color: $white;
      font-size: 1.1rem;
      font-weight: bold;
      width: 100%;
      display: block;
      line-height: 1.8rem;
      max-width: 35ch;
      @media screen and (min-width: 1200px) {
        font-size: 3.5vmin;
        line-height: 5vmin;
      }
    }
    .button-container {
      width: 100%;
      text-decoration: none;
      color: $white;
      @media screen and (max-width: 550px) {
        align-items: center;
      }
    }
    #sfdc-lightblue-circle-deco {
      position: absolute;
      width: 700px;
      height: 320px;
      border-radius: 9000px;
      background-color: $form;
      left: 0;
      bottom: 0;
      transform: translate(-50%, 50%);
      z-index: 1;
    }
  }
  .sbdc-home-section {
    min-height: 60vh !important;
    position: relative;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    .left-layout-container {
      height: 30vmin;
      aspect-ratio: 1;

      position: absolute;
      top: 0;
      left: -13px;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .logo-container {
      display: flex;
      align-items: center;
      justify-content: end;

      @media screen and (max-width: 600px) {
        padding-right: 2rem;
      }
      .img-container {
        height: 25vmin;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .content-container {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 5rem;

      @media screen and (max-width: 600px) {
        padding-inline: 2rem;
      }

      h2 {
        font-size: 6vmin;
        font-weight: 900;
        color: #356aa0;
        margin-bottom: 2vmin;

        @media screen and (max-width: 600px) {
          font-size: 2.5rem;
        }

        span {
          display: block;
        }
      }

      .text-container {
        width: 50%;
        font-size: 1.7vmin;
        color: #1c4065;
        margin-bottom: 3vmin;

        @media screen and (max-width: 600px) {
          font-size: 0.8rem;
          width: 100%;
        }
      }
      .btn-container {
        display: flex;
        gap: 1.5rem;
      }
    }
  }
  //   .sfdc-home-image {
  //     background-color: $white;
  //     position: relative;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     height: 100%;
  //     img[alt="sfdc-logo"] {
  //       max-width: 60%;
  //       max-height: 60%;
  //       object-fit: contain;
  //     }
  //     #lightblue-triangle-deco {
  //       border: 40px solid transparent;
  //       border-right: 40px solid $blue;
  //       position: absolute;
  //       top: 50%;
  //       right: 0;
  //     }
  //   }
  //   .sfdc-home-section {
  //     background-color: $blue;
  //     position: relative;
  //     text-align: left;
  //     overflow: hidden;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     height: 100vh;
  //     @media screen and (max-width: 780px) {
  //       padding-inline: 1em;
  //     }
  //     @media screen and (max-width: 350px) {
  //       padding-inline: 1.5em;
  //     }
  //     .flex-container {
  //       z-index: 3;
  //       width: fit-content;
  //       height: 70%;
  //       z-index: 4;
  //       padding-top: 2em;
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: center;
  //       margin: 0 auto;
  //     }
  //     h4.home-description {
  //       font-size: 1.1rem;
  //       font-weight: bold;
  //       width: 100%;
  //       display: block;
  //       line-height: 1.8rem;
  //       color: $white;
  //       max-width: 35ch;
  //       @media screen and (min-width: 1200px) {
  //         font-size: 1.6em;
  //         line-height: 3rem;
  //       }
  //     }

  //     .button-container {
  //       width: 100%;
  //       text-decoration: none;
  //       color: $white;
  //       margin-top: 5rem;
  //       @media screen and (max-width: 550px) {
  //         align-items: center;
  //       }
  //     }
  //     #sfdc-blue-deco {
  //       position: absolute;
  //       width: 600px;
  //       height: 270px;
  //       border-radius: 9000px;
  //       background-color: $darkblue;
  //       right: 0;
  //       top: 0;
  //       transform: translate(50%, -50%) rotate(90deg);
  //       z-index: 1;
  //     }
  //     #sfdc-skyblue-circle-deco {
  //       position: absolute;
  //       width: 350px;
  //       height: 350px;
  //       border-radius: 50%;
  //       background-color: $form;
  //       left: 0;
  //       bottom: 0;
  //       transform: translate(-50%, 50%);
  //       z-index: 1;
  //     }
  //   }
}
