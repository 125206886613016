@import "../../../../../global.scss";

.who-can-apply-details{
    position: relative;
    margin-top: -7.5rem;
    .who-can-apply-details-header{
        width: 80%;
        background-color: $deepSkyBlue;
        border-bottom-right-radius: 100px;
        padding: 2rem 3rem;
        display: flex;
        align-items: flex-end;
        z-index: 1;
        height: 280px;
        h2{
            color: $white;
            text-transform: uppercase;
            font-weight: 900;
            font-size: 2.7rem;
        }
    }
}

@media screen and (max-width: 900px){
    .who-can-apply-details{
        margin-top: 2rem;
        .who-can-apply-details-header{
            width: 90%;
            margin: 0.5rem;
            height: 150px;
            align-items: center;
            h2{
                font-size: 2.4rem;
            }
        }
    }
}